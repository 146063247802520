<template>
  <div class="wrapper">
    <section class="section-profile-cover section-shaped my-0">
      <!-- Circles background -->
      <!-- <img class="bg-image" src="img/pages/mohamed.jpg" style="width: 100%;" /> -->
      <!-- SVG separator -->
      <div class="separator separator-bottom separator-skew">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-secondary"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </section>
    <section class="section bg-secondary" v-if="hasCollege">
      <div class="container">
        <div class="card card-profile shadow mt--300">
          <div class="px-4">
            <div class="row justify-content-center" v-if="college.college_logo">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image" style="height:120px">
                  <a>
                    <img :src="imageUrl+college.college_logo" class="rounded-circle" />
                  </a>
                </div>
              </div>
              <div
                class="col-lg-4 order-lg-3 text-lg-right align-self-lg-center"
              >
                <!-- <div class="card-profile-actions py-4 mt-lg-0">
                  <base-button
                    tag="a"
                    href="javascript:void(0);"
                    type="info"
                    size="sm"
                    class="mr-4"
                    >Connect</base-button
                  >
                  <base-button
                    tag="a"
                    href="javascript:void(0);"
                    type="default"
                    size="sm"
                    class="float-right"
                    >Message</base-button
                  >
                </div> -->
              </div>
              <div class="col-lg-4 order-lg-1">
                <!-- <div class="card-profile-stats d-flex justify-content-center">
                  <div>
                    <span class="heading">22</span>
                    <span class="description">Friends</span>
                  </div>
                  <div>
                    <span class="heading">10</span>
                    <span class="description">Photos</span>
                  </div>
                  <div>
                    <span class="heading">89</span>
                    <span class="description">Comments</span>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="text-center mt-2">
              <h3>{{college.college_name}}</h3>
              <!-- <div class="h6 font-weight-300">
                <i class="ni location_pin mr-2"></i>Moulvibazar
              </div> -->
              <!-- <div class="h6 mt-4">
                <i class="ni business_briefcase-24 mr-2"></i>Solution Manager -
                Creative Tim Officer
              </div>
              <div>
                <i class="ni education_hat mr-2"></i>University of Computer
                Science
              </div> -->
            </div>
            <div class="mt-3 py-3 border-top text-center">
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <card class="card-blog" v-for="degree in college.degrees" :key="degree.id">
                    <!-- <a href="javascript:;">
                      <img class="img pattern rounded" src="img/ill/p8.svg" />
                    </a> -->
                    <template slot="body">
                      <h5 align="left" class="display-5">{{degree.degree_name}}</h5>
                      <!-- <p align="left">
                        The kit comes with three pre-built pages to help you get
                        started faster.
                      </p> -->
                      <ul class="list-unstyled">
                        <li v-for="event in degree.events" :key="event.id" @click="goToPage('/'+username+'/'+event.id)" style="cursor:pointer">
                          <div class="d-flex align-items-center">
                            <div>
                              <div class="mr-3">
                                <i class="ni ni-check-bold"></i>
                              </div>
                            </div>
                            <div>
                              <p class="mb-0">{{event.event_name}}</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </template>
                  </card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section bg-secondary" v-else>
      <div class="container">
        <div class="card card-profile shadow mt--300">
          <div class="px-4">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image" style="height:120px">
                  <a @click="goToPage('/')">
                    <img src="img/tap_profile.png" class="rounded-circle" />
                  </a>
                </div>
              </div>
              <div
                class="col-lg-4 order-lg-3 text-lg-right align-self-lg-center"
              >
                
              </div>
              <div class="col-lg-4 order-lg-1">
                
              </div>
            </div>
            <div class="text-center mt-5">
              <div data-v-49c4e179="" role="alert" class="alert alert-danger alert-dismissible">
                <span class="alert-inner--icon"><i class="ni ni-support-16"></i></span>
                <span class="alert-inner--text">
                  <div data-v-49c4e179="">
                    <strong data-v-49c4e179="">Error!</strong> College not found. Please try again with proper College. 
                  </div>
                </span>
              </div>  
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
  import RequestService from '../services/request.service';
export default {  
  data() {
    return {
      hasCollege:true,
      college:[],
      imageUrl:'',
      username:''
    };
  },
  mounted(){
    // this.imageUrl = process.env.VUE_APP_IMAGE_URL;
    this.imageUrl = 'https://tap.fee.com.bd/images/colleges/';
    this.username = this.$route.params.username;
    this.getData();    
  },
  methods: {
    async getData(){
      let response =  await RequestService.get('college/'+this.username+'?include=degrees,degrees.events');
      if(response){
        this.college = response;
      }else{
        this.hasCollege = false;
      }
    },
    goToPage(pageLink){
      this.$router.push(pageLink);
    }
  }
};
</script>
<style scoped>
  .section-profile-cover{
    height: 300px;
  }
  @media (max-width: 991.98px){
    .section-profile-cover {
      height: 150px;
    }
  }
</style>
