<template>
  <div class="wrapper">
    <section class="section-profile-cover section-shaped my-0">
      <!-- Circles background -->
      <!-- <img class="bg-image" src="img/pages/mohamed.jpg" style="width: 100%;" /> -->
      <!-- SVG separator -->
      <div class="separator separator-bottom separator-skew">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-secondary"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </section>
    <section class="section bg-secondary">
      <div class="container">
        <div class="card card-profile shadow mt--300">
          <div class="px-4">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image" style="height:120px">
                  <a href="javascript:;">
                    <img src="img/tap_profile.png" class="rounded-circle" />
                  </a>
                </div>
              </div>
              <div
                class="col-lg-4 order-lg-3 text-lg-right align-self-lg-center"
              >
                <!-- <div class="card-profile-actions py-4 mt-lg-0">
                  <base-button
                    tag="a"
                    href="javascript:void(0);"
                    type="info"
                    size="sm"
                    class="mr-4"
                    >Connect</base-button
                  >
                  <base-button
                    tag="a"
                    href="javascript:void(0);"
                    type="default"
                    size="sm"
                    class="float-right"
                    >Message</base-button
                  >
                </div> -->
              </div>
              <div class="col-lg-4 order-lg-1">
                <!-- <div class="card-profile-stats d-flex justify-content-center">
                  <div>
                    <span class="heading">22</span>
                    <span class="description">Friends</span>
                  </div>
                  <div>
                    <span class="heading">10</span>
                    <span class="description">Photos</span>
                  </div>
                  <div>
                    <span class="heading">89</span>
                    <span class="description">Comments</span>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="text-center mt-2">
              <h3>tap</h3>
              <!-- <div class="h6 font-weight-300">
                <i class="ni location_pin mr-2"></i>Bucharest, Romania
              </div>
              <div class="h6 mt-4">
                <i class="ni business_briefcase-24 mr-2"></i>Solution Manager -
                Creative Tim Officer
              </div>
              <div>
                <i class="ni education_hat mr-2"></i>University of Computer
                Science
              </div> -->
            </div>
            <div class="mt-3 py-3 border-top text-center">
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <div class="el-table table table-responsive table-flush align-items-center el-table--fit el-table--enable-row-hover el-table--enable-row-transition">
                    <div class="el-table__body-wrapper is-scrolling-none">
                      <table cellspacing="0" cellpadding="0" border="0" class="el-table__body" style="width:100%">
                        <colgroup><col name="el-table_1_column_1"></colgroup>
                        <tbody>
                          <tr class="el-table__row" v-for="college in colleges" :key="college.id">
                            <td rowspan="1" colspan="1" class="el-table_1_column_1   el-table__cell">
                              <div class="cell">
                                <div class="media align-items-center" @click="goToPage('/'+college.college_unique_username)" style="cursor: pointer;">
                                  <div class="media-body row"  v-if="college.college_logo">  
                                    <div class="col-5" style="text-align:right;padding-right:0px">
                                      <a class="avatar rounded-circle mr-1">
                                        <img alt="Image placeholder" :src="imageUrl+college.college_logo" />
                                      </a>
                                    </div>
                                    <div class="col-7" style="padding-top:20px;text-align:left;padding-left:0px">
                                      <span class="name mb-0 text-sm">{{ college.college_name }}</span>
                                    </div>
                                  </div>
                                  <div class="media-body row"  v-else>  
                                    <div class="col-12" style="text-align:center">
                                      <span class="name mb-0 text-sm">{{ college.college_name }}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="el-table__column-resize-proxy" style="display: none;"></div>
                  </div>



                  <!-- <div class="card-footer bg-white py-4 d-flex justify-content-end">
                    <base-pagination v-model="currentPage" :total="50"></base-pagination>
                  </div> -->
                  <!-- <a href="javascript:;">Show more</a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
  import {
    Table,
    TableColumn,
    DropdownMenu,
    DropdownItem,
    Dropdown
  } from "element-ui";
  import RequestService from '../services/request.service';
// import process from "process";
export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu
  },
  data() {
    return {
      colleges:[],
      imageUrl:'',
      currentPage: 1
    };
  },
  mounted(){
    // this.imageUrl = process.env.VUE_APP_IMAGE_URL;
    this.imageUrl = 'https://tap.fee.com.bd/images/colleges/';
    this.getData();    
  },
  ready() {
    document.getElementsByClass("el-table__cell").style.padding = "0 1rem";
  },
//   computed:{
//     background(){
//         return require("`${process.env.VUE_APP_APIURL}/img/colleges`")
//   }
// },
  methods: {
    async getData(){
      let response =  await RequestService.get('colleges');
      this.colleges = response;
    },
    goToPage(pageLink){
      this.$router.push(pageLink);
    }
  }
};
</script>
<style scoped>
  .section-profile-cover{
    height: 300px;
  }
  @media (max-width: 991.98px){
    .section-profile-cover {
      height: 150px;
    }
  }
</style>
