<template>
  <div class="cd-section" id="contact-us">
    <!--     *********     CONTACT US 1      *********      -->
    <Contact1></Contact1>
    <!--     *********     CONTACT US 2      *********      -->
    <Contact2></Contact2>
    <!--     *********     CONTACT US 3      *********      -->
    <Contact3></Contact3>
    <!--     *********     CONTACT US 4      *********      -->
    <Contact4></Contact4>
  </div>
</template>
<script>
import Contact1 from "./Contact/Contact1";
import Contact2 from "./Contact/Contact2";
import Contact3 from "./Contact/Contact3";
import Contact4 from "./Contact/Contact4";

export default {
  components: {
    Contact1,
    Contact2,
    Contact3,
    Contact4
  }
};
</script>
<style></style>
