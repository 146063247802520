<template>
  <section class="team-1">
    <div class="container">
      <div class="row">
        <div class="col-md-8 ml-auto mr-auto text-center">
          <h3 class="display-3">Our Awesome Team 1</h3>
          <p class="lead">
            People in this world shun people for being great. For being a bright
            color. For standing out. But the time is now to be okay to be the
            greatest you. Would you believe in what you believe in?
          </p>
        </div>
      </div>
      <div class="row"></div>
    </div>

    <b-carousel
      id="carousel1"
      height="100vh"
      class="carousel-team"
      ref="myCarousel"
    >
      <b-carousel-slide class="carousel-inner">
        <div class="container">
          <div class="row">
            <div class="col-md-5 p-5">
              <div class="p-4">
                <img
                  class="rounded shadow transform-perspective-left w-100"
                  src="img/theme/kareya-saleh.jpg"
                  alt="First slide"
                />
              </div>
            </div>
            <div class="col-md-7">
              <div class="wrapper p-md-0">
                <h3 class="card-title display-3 text-left">Charlie Watson</h3>
                <div class="lead text-dark text-left">
                  Artist is a term applied to a person who engages in an
                  activity deemed to be an art. An artist also may be defined
                  unofficially as "a person should is one who expresses him- or
                  herself through a medium". He is should a descriptive term
                  applied to a person who engages in an activity deemed to be an
                  art.
                </div>
                <ul class="list-unstyled mb-0">
                  <li class="py-1">
                    <div class="d-flex align-items-center">
                      <div>
                        <div class="badge badge-circle badge-info mr-3">
                          <i class="ni ni-atom"></i>
                        </div>
                      </div>
                      <div>
                        <p class="mb-1 text-dark">Dedicated entrepreneur</p>
                      </div>
                    </div>
                  </li>
                  <li class="py-1">
                    <div class="d-flex align-items-center">
                      <div>
                        <div class="badge badge-circle badge-success mr-3">
                          <i class="ni ni-user-run"></i>
                        </div>
                      </div>
                      <div>
                        <p class="mb-1 text-dark">Urban exploration</p>
                      </div>
                    </div>
                  </li>
                </ul>
                <div class="footer text-left">
                  <a
                    href="javascript:;"
                    class="btn btn-twitter btn-sm rounded-circle"
                    ><i class="fa fa-twitter"></i
                  ></a>
                  <a
                    href="javascript:;"
                    class="btn btn-facebook btn-sm rounded-circle"
                    ><i class="fa fa-facebook-square"></i
                  ></a>
                  <a
                    href="javascript:;"
                    class="btn btn-dribbble btn-sm rounded-circle"
                    ><i class="fa fa-dribbble"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-carousel-slide>

      <b-carousel-slide class="carousel-inner">
        <div class="container">
          <div class="row">
            <div class="col-md-5 p-5">
              <div class="p-4">
                <img
                  class="rounded shadow transform-perspective-left w-100"
                  src="img/theme/willy-dade.jpg"
                  alt="First slide"
                />
              </div>
            </div>
            <div class="col-md-7">
              <div class="wrapper p-md-0">
                <h3 class="card-title display-3 text-left">Lucy Thomson</h3>
                <div class="lead text-dark text-left">
                  Artist is a term applied to a person who engages in an
                  activity deemed to be an art. An artist also may be defined
                  unofficially as "a person should is one who expresses him- or
                  herself through a medium". He is should a descriptive term
                  applied to a person who engages in an activity deemed to be an
                  art.
                </div>
                <ul class="list-unstyled mb-0">
                  <li class="py-1">
                    <div class="d-flex align-items-center">
                      <div>
                        <div class="badge badge-circle badge-info mr-3">
                          <i class="ni ni-atom"></i>
                        </div>
                      </div>
                      <div>
                        <p class="mb-1 text-dark">Dedicated entrepreneur</p>
                      </div>
                    </div>
                  </li>
                  <li class="py-1">
                    <div class="d-flex align-items-center">
                      <div>
                        <div class="badge badge-circle badge-success mr-3">
                          <i class="ni ni-user-run"></i>
                        </div>
                      </div>
                      <div>
                        <p class="mb-1 text-dark">Urban exploration</p>
                      </div>
                    </div>
                  </li>
                </ul>
                <div class="footer text-left">
                  <a
                    href="javascript:;"
                    class="btn btn-twitter btn-sm rounded-circle"
                    ><i class="fa fa-twitter"></i
                  ></a>
                  <a
                    href="javascript:;"
                    class="btn btn-facebook btn-sm rounded-circle"
                    ><i class="fa fa-facebook-square"></i
                  ></a>
                  <a
                    href="javascript:;"
                    class="btn btn-dribbble btn-sm rounded-circle"
                    ><i class="fa fa-dribbble"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-carousel-slide>

      <b-carousel-slide class="carousel-inner">
        <div class="container">
          <div class="row">
            <div class="col-md-5 p-5">
              <div class="p-4">
                <img
                  class="rounded shadow transform-perspective-left w-100"
                  src="img/theme/lucy.jpg"
                  alt="First slide"
                />
              </div>
            </div>
            <div class="col-md-7">
              <div class="wrapper p-md-0">
                <h3 class="card-title display-3 text-left">Quavo Austen</h3>
                <div class="lead text-dark text-left">
                  Artist is a term applied to a person who engages in an
                  activity deemed to be an art. An artist also may be defined
                  unofficially as "a person should is one who expresses him- or
                  herself through a medium". He is should a descriptive term
                  applied to a person who engages in an activity deemed to be an
                  art.
                </div>
                <ul class="list-unstyled mb-0">
                  <li class="py-1">
                    <div class="d-flex align-items-center">
                      <div>
                        <div class="badge badge-circle badge-info mr-3">
                          <i class="ni ni-atom"></i>
                        </div>
                      </div>
                      <div>
                        <p class="mb-1 text-dark">Dedicated entrepreneur</p>
                      </div>
                    </div>
                  </li>
                  <li class="py-1">
                    <div class="d-flex align-items-center">
                      <div>
                        <div class="badge badge-circle badge-success mr-3">
                          <i class="ni ni-user-run"></i>
                        </div>
                      </div>
                      <div>
                        <p class="mb-1 text-dark">Urban exploration</p>
                      </div>
                    </div>
                  </li>
                </ul>
                <div class="footer text-left">
                  <a
                    href="javascript:;"
                    class="btn btn-twitter btn-sm rounded-circle"
                    ><i class="fa fa-twitter"></i
                  ></a>
                  <a
                    href="javascript:;"
                    class="btn btn-facebook btn-sm rounded-circle"
                    ><i class="fa fa-facebook-square"></i
                  ></a>
                  <a
                    href="javascript:;"
                    class="btn btn-dribbble btn-sm rounded-circle"
                    ><i class="fa fa-dribbble"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-carousel-slide>

      <a
        class="carousel-control-prev text-primary"
        v-on:click="prev"
        role="button"
        data-slide="prev"
      >
        <i class="ni ni-bold-left"></i>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next text-primary"
        v-on:click="next"
        role="button"
        data-slide="next"
      >
        <i class="ni ni-bold-right"></i>
        <span class="sr-only">Next</span>
      </a>
    </b-carousel>
  </section>
</template>
<script>
import { BCarousel } from "bootstrap-vue/esm/components/carousel/carousel";
import { BCarouselSlide } from "bootstrap-vue/esm/components/carousel/carousel-slide";

export default {
  components: {
    BCarousel,
    BCarouselSlide
  },
  methods: {
    prev() {
      this.$refs.myCarousel.prev();
    },
    next() {
      this.$refs.myCarousel.next();
    }
  }
};
</script>
<style>
.team-1 .carousel-control-prev,
.team-1 .carousel-control-next {
  z-index: 9999;
}
</style>
