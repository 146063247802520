<template>
  <div class="wrapper">
    <section class="upper">
      <div class="container">
        <div class="row">
          <div class="col-md-12 mx-auto">
            <div class="card card-invoice" id="printArea" ref="printArea">
              <div class="card-header text-center" style="background-color: #fff;">
                <div data-v-49c4e179="" role="alert" class="alert alert-danger alert-dismissible" v-if="showAlert">
                  <span class="alert-inner--icon"><i class="ni ni-support-16"></i></span>
                  <span class="alert-inner--text">
                    <div data-v-49c4e179="">
                      {{alert}}
                    </div>
                  </span>
                </div>
                <div class="row justify-content-between" v-if="!showAlert">
                  <div class="col-md-4 text-left">
                    <img
                      v-if="event.degree.college.college_logo"
                      class="mb-2"
                      :src="imageUrl+event.degree.college.college_logo"
                      alt="Logo"
                      style="max-width:110px"
                    />
                    <h6>
                      {{event.degree.college.college_name}}
                    </h6>
                  </div>
                  <div class="col-md-4 text-center mt-2">
                    <h2>
                      {{event.event_name}}
                    </h2>
                    <h4>
                      {{event.degree.degree_name}}
                    </h4>
                    <h5>
                      Student ID: {{student.student_id}}  @ {{transaction.college_amount}} BDT
                    </h5>
                  </div>
                  <div class="col-md-4">
                    <div class="row text-right">
                      <img
                        class="mb-2"
                        :src="baseUrl+'/img/tap_logo.png'"
                        alt="Logo"
                        style="height:110px;margin-left:auto"
                      />
                    </div>
                    
                  </div>
                </div>
                <br />
                <div class="row justify-content-md-between" v-if="!showAlert">
                  <div class="col-md-4">
                    <div class="row text-left">
                      <h4 style="width:100%"  class="mb-1">Student Information:<br /></h4>
                      <!-- <span class="d-block">James Thompson</span> -->
                      <p align="left" style="width:100%;color: #383271;">
                        Name: {{student.student_name}}<br />
                        Student ID: {{student.student_id}}<br />
                        Mobile: {{transaction.customer_mobile}}<br />
                        Class: {{student.class}}<br />
                        Group: {{student.group}}<br />Section: {{student.section}}<br />Roll: {{student.roll}}
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-5 col-md-6">
                    <div class="row text-right">
                      <h4 style="width:100%"  class="mb-1">Payment Details:<br /></h4>
                      <!-- <span class="d-block">James Thompson</span> -->
                      <p align="right" style="width:100%;color: #383271;">
                        Invoice number: {{transaction.invoice_number}}<br />
                        Invoice date: {{transaction.created_at}}<br />
                        Transaction ID: {{transaction.transaction_id}} <br />
                        Total Amount: {{transaction.total_amount}}<br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body" v-if="!showAlert">
                <div class="row">
                  <div class="col-12">
                    <div class="table-responsive">
                      <table class="table text-right">
                        <thead style="rgb(184 244 255) !important">
                          <tr>
                            <th style="font-size: 1rem;" scope="col" class="text-right text-black">
                              Degree(Event)
                            </th>
                            <th style="font-size: 1rem;" scope="col" class="text-right text-black">
                              Particular
                            </th>
                            <th style="font-size: 1rem;" scope="col" class="text-right text-black">
                              Amount (BDT)
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style="font-size: 1rem;">{{event.degree.degree_name}}<br>({{event.event_name}})</td>
                            <td style="font-size: 1rem;">Fee</td>
                            <td style="font-size: 1rem;">{{transaction.college_amount}}</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td style="font-size: 1rem;">Service Charge</td>
                            <td style="font-size: 1rem;">{{transaction.tap_amount}}</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <th></th>
                            <th class="h4" style="font-size: 1rem;">Total</th>
                            <th colspan="3" style="font-size: 1rem;" class="text-right h4">{{transaction.total_amount}}</th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer text-center" style="background-color: #fff;">
                <div class="col-md-12">
                  <!-- <h5>Thank you!</h5> -->
                  <p class="description" style="color: #383271;">
                    This is a computer generated invoice, hence no signature is required
                  </p>
                  <!-- <h6 class="d-block">
                    email:
                    <small class="text-muted">support@fee.com.bd</small>
                  </h6> -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 ml-auto">
            <!-- <button type="button" @click="print()" class="btn btn-print mt-3 mb-6 btn-primary">Print</button> -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
  import RequestService from '../services/request.service';

export default {
  data() {
    return {
      showAlert: false,
      alert: "",
      baseUrl: window.location.origin,
      event:{
        event_name: "",
        degree_id: "",
        end_date_time: "",
        start_date_time: "",
        status: "",
        type: "",
        degree:{
          college: {
            college_banner: "",
            college_logo: "",
            college_name: "",
            college_unique_username: "",
            id: "",
            status: "",
            credential:{
              id: "",
              user_name: "",
              password:''
            },
          },
          college_id: "",
          degree_name: "",
          id: "",
          status: ''
        }
      },
      imageUrl: "",
      apiUrl: "",
      username: "",
      studentId: "",
      requesterReferenceId: "",
      eventId: "",
      student:{
        id: "",
        student_id: "",
        student_name: "",
        event_id: "",
        class: "",
        group: "",
        section: "",
        roll: "",
        amount: "",
        payment_status: "UNPAID"
      },
      transaction:{
        invoice_number: "",
        id: "",
        college_amount: "",
        tap_amount: "",
        total_amount: "",
        status: "",
        transaction_id: "",
        created_at: ""
      },
    };
  },
  mounted(){
    window.test=this;
    this.eventId = this.$route.params.id;
    this.username = this.$route.params.username;
    this.studentId = this.$route.params.studentId;
    this.requesterReferenceId = this.$route.params.requesterReferenceId;
    // this.imageUrl = process.env.VUE_APP_IMAGE_URL;
    this.imageUrl = 'https://tap.fee.com.bd/images/colleges/';

    // this.apiUrl = process.env.VUE_APP_API_URL;
    this.apiUrl = 'https://xggk3kgs0j.execute-api.ap-southeast-1.amazonaws.com/';
    this.getData();    
  },
  methods: {
    async getData(){
      let response =  await RequestService.get('transaction/'+this.requesterReferenceId+'?include=student.event.degree.college');
      if(response){
        this.transaction = response;
        if(response.student){
          this.student = response.student
          if(response.student.event){
            this.event = response.student.event;
            await new Promise(resolve => setTimeout(resolve, 1000)); 
            this.print();
          }else{
            this.alert = 'Event not found. Please try again with proper Event.';
            this.showAlert = true;
          }
          this.student.amount = response.college_amount;
        }else{
          this.alert = 'Student not found. Please try again with proper ID.';
          this.showAlert = true;
        }
      }else{
        this.alert = 'Transaction not found. Please try again with proper ID.';
        this.showAlert = true;
      }
    },
    goToPage(pageLink){
      this.$router.push(pageLink);
    },
    print(){
      var divName = 'printArea'
      // console.log(divName);
      var printContents = document.getElementById(divName).innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    }
  }
};
</script>
<style scoped>
  .section-profile-cover{
    height: 300px;
  }
  @media (max-width: 991.98px){
    .section-profile-cover {
      height: 150px;
    }
  }
  
</style>
